/* RESPONSIBLE TEAM: team-help-desk-experience */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import storage from 'embercom/vendor/intercom/storage';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default IntercomRoute.extend({
  permissionsService: service(),
  router: service(),
  appService: service(),
  app: readOnly('appService.app'),
  analytics: {
    section: 'platform',
    place: 'feed',
  },
  beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_contacts', transition);
    if (this.appService.app.hasOptedInForProductIa) {
      this.router.transitionTo('apps.app.users.segments.segment', 'all');
    }
  },
  activate() {
    storage.set('lastProductUsed', 'feed');
  },
});
