/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import Admin from 'embercom/models/admin';
import { allSettled, hash } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class InviteRoute extends Route {
  @service store;
  @service intercomEventService;
  @service iamService;
  @service router;
  @service intl;

  @tracked currentAdmin;
  @tracked invite;

  analytics = {
    section: 'signup',
    place: 'invite',
  };

  get currentAdminAppIds() {
    return this.currentAdmin?.apps.map((app) => app.id) || [];
  }

  get doesNotHaveCustomDataAttributes() {
    return !this.invite.hasCustomDataAttributes;
  }

  get shouldRedirectToGuide() {
    return this.adminAlreadyOnApp && this.doesNotHaveCustomDataAttributes;
  }

  get adminAlreadyOnApp() {
    return this.currentAdminAppIds.includes(this.invite.appIdCode);
  }

  async beforeModel() {
    await this.intl.switchLocale(navigator.language);
    this.iamService.boot();
    return allSettled(
      [
        Admin.me(false).then((admin) => {
          this.currentAdmin = admin;
        }),
      ],
      'load admin',
    );
  }

  model(params) {
    return this.store.findRecord('invite', params.token).then((invite) => {
      let untrackedCdas;

      if (invite.hasCustomDataAttributes) {
        this.intercomEventService.trackEvent('invite-link-visited', {
          action: 'visited',
          object: 'cda_invite_link',
          place: 'invite_page',
          owner: 'growth',
        });

        untrackedCdas = ajax({
          url: `/ember/untracked_custom_data_attributes?invite_token=${params.token}`,
          type: 'GET',
        });
      }

      return hash({
        invite,
        untrackedCdas,
      });
    });
  }

  afterModel(model) {
    this.invite = model.invite;
    if (this.shouldRedirectToGuide) {
      this.transitionToGuide(model.invite);
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      untrackedCdas: model.untrackedCdas,
      adminAlreadyOnApp: this.adminAlreadyOnApp,
      currentAdmin: this.currentAdmin,
    });
  }

  _getRouteParameters(invite) {
    let appId = invite.appIdCode;
    let guideChecklistKey = invite.guideChecklistKey;
    let guideTaskKey = invite.guideTaskKey;

    let onboardingUseCasekey = invite.onboardingUseCaseKey;
    let onboardingTaskKey = invite.onboardingTaskKey;

    let onboardingHomeStepIdentifier = invite.onboardingHomeStepIdentifier;

    let forMessengerInstallation = invite.forMessengerInstallation;

    if (guideChecklistKey && guideTaskKey) {
      return [
        `${this.guideRoutePath(invite)}.checklist.task`,
        appId,
        guideChecklistKey,
        guideTaskKey,
      ];
    } else if (onboardingHomeStepIdentifier) {
      return ['apps.app.home', appId, { queryParams: { step: onboardingHomeStepIdentifier } }];
    } else if (onboardingUseCasekey && onboardingTaskKey) {
      return ['apps.app.home', appId];
    } else if (forMessengerInstallation) {
      return [
        'apps.app.settings.channels.messenger.install',
        appId,
        {
          queryParams: {
            engineerInvite: true,
          },
        },
      ];
    } else {
      return ['apps.app', appId];
    }
  }

  guideRoutePath(invite) {
    return 'apps.app.home';
  }

  transitionToGuide(invite) {
    let routeParameters = this._getRouteParameters(invite, false);
    this.transitionTo.apply(this, routeParameters);
  }

  @action error(error) {
    if (error?.errorThrown === 'Gone' || error?.jqXHR?.status === 410) {
      this.router.transitionTo('invites.expired');
    } else if (error?.jqXHR?.status === 422) {
      this.router.transitionTo('invites.invalid');
    } else if (error?.jqXHR?.status === 404) {
      document.location.href = '/404.html';
    }
  }

  @action didTransition() {
    let model = this.controller.get('model');
    if (model.invite.guideTaskKey) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: model.invite,
      });
    }
  }

  /**
   * Forces the app to go through rails, allowing it to properly handle
   * things like two-factor authentication.
   */
  @action hardRedirectToGuide(invite) {
    let routeParameters = this._getRouteParameters(invite);
    let url = this.router.urlFor.apply(this.router, routeParameters);

    document.location.href = url;
  }
}
