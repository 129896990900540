/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import { get } from 'embercom/lib/ajax';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type IntlService from 'embercom/services/intl';
import type ArticleGroup from 'embercom/models/articles/article-group';
import RSVP from 'rsvp';
import type HelpcenterController from 'embercom/controllers/apps/app/settings/helpcenter/workspace-helpcenter';

export interface CollectionModel {
  allCollections: any;
  allArticlesSummaries: any;
}

export default class CollectionRoute extends IntercomRoute {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @tracked createNewCollectionAction: any;

  @action
  registerTreeAction(createNewCollectionAction: any) {
    this.controllerFor('apps.app.settings.helpcenter.workspace-helpcenter.collections').set(
      // @ts-ignore
      'createNewCollectionAction',
      createNewCollectionAction,
    );
  }

  async model(): Promise<CollectionModel | undefined> {
    let model;
    try {
      if (this.app.sideBySideCollectionsPreview) {
        // Code to get the preview appearing alongside collections
        let controller = this.controllerFor(
          'apps.app.settings.helpcenter.workspace-helpcenter',
        ) as HelpcenterController;
        let uploadHelper = controller.uploadHelper;
        model = await RSVP.hash({
          allCollections: this.getAllCollections(this.helpCenterService.site.id),
          allArticlesSummaries: this.getAllArticleSummariesInGroups(this.helpCenterService.site.id),
          uploadHelper,
        });
      } else {
        model = await RSVP.hash({
          allCollections: this.getAllCollections(this.helpCenterService.site.id),
          allArticlesSummaries: this.getAllArticleSummariesInGroups(this.helpCenterService.site.id),
        });
      }
    } catch (error) {
      this.intercomEventService.trackEvent('educate-event', {
        action: 'error',
        object: 'site_collection_list',
        place: 'site_collection_list',
        owner: 'educate',
        error: error.message,
      });

      this.transitionTo('apps.app.articles');
    }

    return model;
  }

  afterModel(model: CollectionModel, transition: Transition) {
    let { allCollections } = model;
    let collectionId = transition.to.queryParams.collectionId;
    allCollections.forEach(
      (collection: ArticleGroup) => (collection.shouldFocusName = collectionId === collection.id),
    );
  }

  getAllCollections(helpCenterId: string) {
    return this.store.query('articles/article-group', {
      include_article_stats: true,
      help_center_ids: [helpCenterId],
    });
  }

  async getAllArticleSummariesInGroups(helpCenterId: string) {
    return get(
      `/ember/articles_collections/articles/?app_id=${this.app.id}&help_center_id=${helpCenterId}`,
    );
  }

  get app() {
    return this.appService.app;
  }
}
