/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type ReportingViews from 'embercom/services/reporting-views';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import type CustomReportsService from 'embercom/services/custom-reports-service';

export default class ViewRoute extends Route {
  @service declare appService: any;
  @service declare reportingViews: ReportingViews;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @service declare customReportsService: CustomReportsService;

  titleToken = '...';

  async model(params: any) {
    let viewIds = this.reportingViews.dynamicViews.map((view) => view.id);
    if (!viewIds.includes(params.reporting_view_id)) {
      return this.router.transitionTo('apps.app.reports.views.view', 'all-reports');
    }

    if (this.reportingViews.canViewCustomReports) {
      await taskFor(this.reportingViews.refreshReports).perform();
    }
    let view = this.reportingViews.findView(params.reporting_view_id);
    this.titleToken = this.intl.t(view!.name);
    return view!;
  }

  @action
  async newReportPermissions() {
    if (!this.customReportsService.canChangeCustomReports) {
      this.customReportsService.loadChangePermissionModal();
    } else {
      this.router.transitionTo('apps.app.reports.custom-reports.report.new');
    }
  }
}
